<template>
  <div style="min-height: 500px;margin-top: 3.5vw;">
    <el-row>
      <el-col :span="24">
        <div class="container">
          <!-- 表头 -->
          <div class="achievement-content-title">
            <div class="achievement-title-name" v-html="item.projectName || '-'"></div>
            <div class="dis-sta-cen" style="margin-top: 20px;">
              <div class="" v-if="item.isfocus === '4'">
                <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                  <span  class="focus-enterprises-tag-yellow">未核验</span>
                </el-tooltip>
              </div>
              <div class="achievement-tag-gray">企业名称：{{ item.companyName || '-' }}</div>
              <!-- <div class="achievement-tag-gray" v-if="item.province && item.city">省份地区：{{ item.province || '-' }} - {{ item.city || '-' }}</div> -->
              <div class="achievement-tag-gray" v-if="item.province">省份地区：{{ item.province || '-' }}</div>
              <div class="achievement-tag-gray" v-if="item.city">城市：{{ item.city || '-' }}</div>
              <div class="achievement-tag-gray">中标日期：{{ item.timeSuccessStr || item.timeSuccess || '-' }}</div>
            </div>
          </div>
          <div class="achievement-content-title">
            <div class="achievement-title-remind">公告摘要</div>
            <div>
              <el-descriptions class="margin-top" :column="2" :size="size" border>
                  <el-descriptions-item>
                    <template #label>项目名称</template>
                    <span v-html="item.projectName || '-'"></span>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template #label>所属省份</template>
                    {{ item.province || '-' }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template #label>项目单位</template>
                    <!-- {{ item.companyName || '-' }} -->
                    {{ dataTable && dataTable.details && dataTable.details.projectConstructor ? dataTable.details.projectConstructor : '-' }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template #label>中标金额</template>
                    {{ dataTable && dataTable.details && dataTable.details.money ? dataTable.details.money + '元' : '-' }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template #label>原文链接</template>
                   <div style="cursor: pointer;color: #5D6FE9;" @click="contentClick()"> {{ dataTable?.details?.url ? dataTable?.details?.url : '暂无详情链接' }}</div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template #label>中标日期</template>
                    {{ item.timeSuccessStr || item.timeSuccess || '-' }}
                  </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="achievement-title-remind" v-if="dataTable.content" style="margin-top: 30px;">公告正文</div>
            <div style="text-align: left;" class="content-position" v-html="dataTable.content"></div>
             <div class="achievement-title-remind" v-if="dataTable.content" style="margin-top: 30px;">
                <el-button type="primary" style="background-color: #5D6FE9;" @click="contentClick()">查看原文</el-button>
             </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBG } from '@/api/forward'
export default {
  name: 'company-achievement-details',
  data () {
    return {
      item: {},
      dataTable: {}
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('company-achievement-details-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      vm.item = item
    }
    vm.init()
  },
  methods: {
    init () {
      const vm = this
      if (!vm.item.id) {
        return ElMessage.warning({
          message: '未获取到id',
          type: 'warning',
          offset: 60
        })
      }
      const data = {
        dateBackgroundUrl: '/tCompanyAchievement/getBidHtmlContent',
        dateBackgroundRequestType: 'get',
        data: {
          achievementId: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          vm.dataTable = result.data ? result.data : {}
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 查看原文
    contentClick () {
      const vm = this
      window.open(vm.dataTable.url)
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.achievement-content-title {
  margin: 30px 0 20px 0;
  padding: 30px 20px;
  border: 1px solid #E6E6E6;
  background-color: #fff;
  width: 100%;
  .achievement-title-name {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    text-align: left;
  }
  .focus-enterprises-tag-yellow {
    color: #EA9920;
    padding: 0 5px;
    border: 1px solid #EA9920;
    cursor: pointer;
    font-size: 12px;
    margin-right: 60px;
  }
  .achievement-tag-gray {
    color: #666;
    font-size: 14px;
    margin-right: 60px;
  }
  .achievement-title-remind {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
    color: #333333;
  }
  /deep/ .el-descriptions :not(.is-bordered) td, .el-descriptions :not(.is-bordered) th {
    max-width: 200px;
  }
}
/deep/ .content-position div {
  position: relative !important;
}
</style>
